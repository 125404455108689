<!--suppress XmlInvalidId -->
<template>
  <h1>Register new user</h1>
  <UserForm
    @submit="onCreate"
    @cancel="onCancel"
  />
</template>

<script>
import { useRouter } from "vue-router"
import UserService from "@/services/UserService"

export default {
  setup() {
    const router = useRouter()
    const userService = new UserService()

    function onCreate(user) {
      userService.addUser(user).then((result) => {
        router.push({ name: "userProfile", params: { user_id: result } })
      })
    }

    function onCancel() {
      router.go(-1)
    }

    return {
      onCreate,
      onCancel,
    }
  },
}
</script>
